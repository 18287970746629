<template>
  <div class="carousel-container scroll-container" @wheel="handleWheel" @wheel.prevent>
    <!-- 顶部导航栏指示器 -->
    <div class="carousel-nav" style="height: 100px;">
      <div style="display: flex;height: 60px;line-height: 60px;justify-content: center;">
        <div style="width: 19%;text-align: left;position:relative;overflow: hidden">
          <img src="@/assets/logo.png" style="width: 50%;">
        </div>
        <div style="width: 59%;display: flex;justify-content: right">
          <div style="margin: 0 20px;" v-for="(page, index) in carouselPages" :key="index" class="navTitle"
               :class="{ active: index === currentPage}" @click="goToPage(index)">
            <div v-if="index===6&&language==='1'" @click="goToPage(6)" :class="{ active: 6 === currentPage}"
                 class="navTitle">报告认证
              <img src="@/assets/hot.png" style="width:20px;height: 26px;top:10%;right:-25px;position: absolute;">
            </div>
            <div v-if="index!==6&&language==='1'">{{ page.title }}</div>

            <div v-if="index===6&&language==='2'" @click="goToPage(6)" :class="{ active: 6 === currentPage}"
                 class="navTitle">REPORTS & CERTIFICATIONS
              <img src="@/assets/hot.png" style="width:20px;height: 26px;top:10%;right:-25px;position: absolute;">
            </div>
            <div v-if="index!==6&&language==='2'">{{ page.Eg }}</div>
          </div>
          <div class="navTitle" style="margin: 0 20px;" @click="changeLanguage()">
            <span v-if="language==='1'">简体中文</span>
            <span v-if="language==='2'">English</span>
            ▼
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-track scroll-content"
         :style="{ transform: `translateY(-${currentPage * 100}%)`,transition:'all .8s' }">
      <div style="overflow: hidden" class="carousel-page" v-for="(page, index) in carouselPages" :key="index">
        <!-- 第一页 -->
        <div v-if="index===0"
             style="width: 100%;text-align: left;position: relative;display: flex;justify-content: center;flex-direction: column"
             class="onePage">
          <!-- 文字1内容 -->
          <div
              style="transition: all 2s;margin-left: 10%;width: 80%;display: flex;align-items: center;flex-direction: column;justify-content: center;min-height: 200px;">
            <div style="color: #f79210;margin-bottom: 70px; font-size: 24px;" class="smallTitle">
              <span v-if="language==='1'">以顶级安全专家订阅服务为核心</span>
              <span v-else>Centered on Subscription Services from Top Security Experts</span>
            </div>
            <div style="font-size: 70px;background: linear-gradient(90deg,#fff,#fb8d05)" class="technique">
              <span v-if="language==='1'">聚焦体系 绝对防御</span>
              <span v-else>Focus on System & Absolute Defense</span>
            </div>
          </div>
          <!-- 文字2内容 -->
          <div
              style="position:relative;color: white;min-height: 200px;;display: flex;align-items: center;flex-direction: column;justify-content: center;">
            <div>
              <p style="transition: margin-top 2s;margin-bottom: 90px;font-size: 24px;margin-top: 1%"
                 class="introduction">
                <span v-if="language==='1'">构建企业资产的绝对防御体系</span>
                <span v-else>Building an Absolute Defense System for Enterprise Assets</span>
              </p>
            </div>
            <div style="transition: margin-top 2s;overflow:hidden;z-index: 10;margin-top: -5px;"
                 class="introductionButton">
              <div style="display: flex;">
                <div style="padding:16px 20px;font-size: 24px;" class="infoButton" @click="currentPage=1">
                  <span v-if="language==='1'">了解更多</span>
                  <span v-else>Learn More</span>
                </div>
                <div style="padding:16px 20px;font-size: 24px;" class="infoButtonHover" @click="currentPage=5">
                  <span v-if="language==='1'">联系我们</span>
                  <span v-else>Contact Us</span>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;background:#ffffff;height: 20px;position: absolute;z-index: 100"
               :style="{bottom:lineBottom}"></div>
        </div>
        <!-- 第一页end -->

        <div v-if="index===1" style="width: 100%;z-index: 88;position: relative" class="towPage">
          <div style="width: 100%;background:#ffffff;height: 20px;position: absolute;z-index: 100"
               :style="{top:lineTop}"></div>
          <div style="color: white;margin-top: 10%;font-size: 17px;">
            <span v-if="language==='1'">区块链安全的绝对防御：从基础安全到应用安全的一体化服务</span>
            <span v-else>Absolute Defense for Blockchain Security - Comprehensive Services from Basic Security to
              Application Security</span>
          </div>
          <div style="width: 70%;margin: 50px auto 0 auto;">
            <div style="width: 100%;display: flex;justify-content: space-between;flex-wrap: wrap">
              <div class="infoBlock_content">
                <div class="info_block" @click="toService()">
                  <div style="padding: 20px;text-align: left">
                    <div style="">
                      <img src="@/assets/page/icon_1.svg" class="icon_img">
                      <img src="@/assets/page/icon_1.svg" class="icon_img_active">
                    </div>
                    <div class="info_block_text">
                      <span v-if="language==='1'">安全专家订阅</span>
                      <span v-else>Security Expert Subscription</span>
                    </div>
                    <div class="introduce">
                      <div v-if="language==='1'">
                        结合区块链行业信息安全管理“最佳实践”，以风险管理为核心，基于客户业务现状，对标顶级交易所，
                        为客户建立涵盖信息安全纵深防御体系、规范和流程的整体安全管理体系，
                        协助客户全面、系统、持续提高组织的信息安全管理水平，
                        确保在攻击威胁的挑战环境中,百分百保障企业资产安全。
                      </div>
                      <div v-else class="egText">
                        In line with the best practices of information security management within the blockchain
                        industry,with risk management as the core,based on the current situation of customers' business,
                        benchmarking against top exchanges.
                        Establish a comprehensive security management system for clients that includes a deep defense
                        system for information security, standards, and processes.
                        Help clients enhance their organizational information security management in a comprehensive,
                        systematic, and continuous manner.
                        Ensure the security of corporate assets by 100% protection in the face of attack threats and
                        challenges.
                      </div>
                    </div>
                    <a v-if="language==='1'">了解详情</a>
                    <a v-else>Learn More</a>
                  </div>
                </div>
              </div>
              <div class="infoBlock_content">
                <div class="info_block" @click="toService()">
                  <div style="padding: 20px;text-align: left">
                    <div style="">
                      <img src="@/assets/page/icon_2.svg" class="icon_img">
                      <img src="@/assets/page/icon_2.svg" class="icon_img_active">
                    </div>
                    <div class="info_block_text">
                      <span v-if="language==='1'">安全建设</span>
                      <span v-else>Security construction</span>
                    </div>
                    <div class="introduce">
                      <div v-if="language==='1'">
                        通过分解业务场景、暴露面统计、深入了解企业风险点，
                        划定攻击路径，基于当前的防护弱项和盲区进行针对性加强防御，
                        通过部署安全防护、安全培训、制度管控等多项建设，
                        让企业安全实现绝对防御。
                      </div>
                      <div v-else class="egText">
                        Analyze business scenarios, identify exposure points, and deeply understand enterprise risk
                        factors to outline potential attack paths.
                        Strengthen defenses specifically based on current vulnerabilities and priority areas.
                        Achieve absolute security for the enterprise through the deployment of protective measures,
                        safety training, and regulatory control.
                      </div>
                    </div>
                    <a v-if="language==='1'">了解详情</a>
                    <a v-else>Learn More</a>
                  </div>
                </div>
              </div>
              <div class="infoBlock_content">
                <div class="info_block" @click="toService()">
                  <div style="padding: 20px;text-align: left">
                    <div style="">
                      <img src="@/assets/page/icon_3.svg" class="icon_img">
                      <img src="@/assets/page/icon_3.svg" class="icon_img_active">
                    </div>
                    <div class="info_block_text">
                      <span v-if="language==='1'">安全运营</span>
                      <span v-else>Security operations</span>
                    </div>
                    <div class="introduce">
                      <div v-if="language==='1'">
                        以企业资产安全的绝对安全为目标，通过统筹运用多种技术和管理手段，
                        将安全能力持续输出传递给企业的过程，其核心价值在于主动发现问题、
                        多次验证问题、深度分析问题、极速响应处置和解决问题，不断迭代优
                        化黑客攻击的处置能力，以此保障企业资产的绝对安全。
                      </div>
                      <div v-else class="egText">
                        Aim for the absolute security of corporate assets.
                        Continuously enhance enterprise security capabilities through integrated technology and
                        management.
                        The core value is proactive issue detection, in-depth analysis, and swift response and
                        resolution.
                        Continuously iterate and optimize the disposal ability of hacker attacks to ensure the absolute
                        security of enterprise assets.
                      </div>
                    </div>
                    <a v-if="language==='1'">了解详情</a>
                    <a v-else>Learn More</a>
                  </div>
                </div>
              </div>
              <div class="infoBlock_content">
                <div class="info_block" @click="toService()">
                  <div style="padding: 20px;text-align: left">
                    <div style="">
                      <img src="@/assets/page/icon_4.svg" class="icon_img">
                      <img src="@/assets/page/icon_4.svg" class="icon_img_active">
                    </div>
                    <div class="info_block_text">
                      <span v-if="language==='1'">智能合约安全审计</span>
                      <span v-else>Smart Contract Audit</span>
                    </div>
                    <div class="introduce">
                      <div v-if="language==='1'">
                        牧色科技在智能合约审计方向具有丰富的实战经验，审计智能合约项目1000多份，
                        挖掘近千个中高危漏洞， 目前已审计涵盖以太坊(Ethereum)及相关EVM兼容链、
                        EOS、Solana等公链，采用AI的自动化审计及人工审计方式，针对安全问题提出详细的修复方案。
                      </div>
                      <div v-else class="egText">
                        Musesec boasts extensive hands-on experience in smart contract auditing.
                        We have audited over 1000 smart contract projects, identifying nearly a thousand medium to
                        high-risk vulnerabilities.
                        Our audits cover mainstream public chains including Ethereum and EVM-compatible chains, EOS,
                        Fabric, Solana, Klaytn, Aptos, and more, addressing over 20 common vulnerabilities.
                        Leveraging AI for automated smart contract auditing alongside manual reviews, our team has
                        discovered hundreds of critical vulnerabilities. We offer detailed remediation
                        strategies for security issues and tailor professional security audit reports to meet the
                        specific needs of projects, ensuring the mitigation of potential risks.
                      </div>
                    </div>
                    <a v-if="language==='1'">了解详情</a>
                    <a v-else>Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;background:#ffffff;height: 20px;position: absolute;z-index: 100;"
               :style="{bottom:oneLineBottom}"></div>
        </div>

        <div v-if="index===2" style="width: 100%;z-index: 88;position: relative" class="threePage">
          <div style="width: 100%;background:#ffffff;height: 20px;position: absolute;z-index: 100"
               :style="{top:twoLineTop}"></div>
          <div style="color: white;margin-top: 7%;font-size: 17px;">
            <span v-if="language==='1'">世界顶尖的安全产品是实现绝对防御的基础</span>
            <span v-else>The world's top security products are the foundation for achieving absolute defense.</span>
          </div>
          <div style="width: 70%;margin: 50px auto 0 auto;">
            <div style="width: 100%;display: flex;justify-content: space-between;flex-wrap: wrap">
              <div class="introduceBlock_content" @click="toProduct()">
                <div class="introduce_block">
                  <div class="introduce_block_img">
                    <img src="@/assets/page/icon_1.gif">
                  </div>
                  <div style="text-align: center;padding: 10px 20px;">
                    <div style="color: white;margin-top: 70px">
                      <span v-if="language==='1'">终端安全产品</span>
                      <span v-else>Endpoint Security Products</span>
                    </div>
                    <div class="introduce" style="margin-top: 10px">
                      <span v-if="language==='1'">保护个人电脑、移动设备等终端设备不受恶意软件和其他安全威胁的影响。</span>
                      <span v-else>Protect personal computers, mobile devices, and other endpoints from malware and
                        other security threats.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="introduceBlock_content" @click="toProduct()">
                <div class="introduce_block">
                  <div class="introduce_block_img">
                    <img src="@/assets/page/icon_2.gif">
                  </div>
                  <div style="text-align: center;padding: 10px 20px;">
                    <div style="color: white;margin-top: 70px">
                      <span v-if="language==='1'">主机安全产品</span>
                      <span v-else>Host Security Products</span>
                    </div>
                    <div class="introduce" style="margin-top: 10px">
                      <span v-if="language==='1'">保护服务器和其他主机系统，防止未授权访问和恶意活动。</span>
                      <span v-else>Protect servers and other host systems from unauthorized access and malicious
                        activities.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="introduceBlock_content" @click="toProduct()">
                <div class="introduce_block_three introduce_block">
                  <div class="introduce_block_img">
                    <img src="@/assets/page/icon_3.gif">
                  </div>
                  <div style="text-align: center;padding: 10px 20px;">
                    <div style="color: white;margin-top: 70px">
                      <span v-if="language==='1'">网络安全产品</span>
                      <span v-else>Network Security Products</span>
                    </div>
                    <div class="introduce" style="margin-top: 10px">
                      <span v-if="language==='1'">确保网络层面的安全，包括防火墙、入侵检测系统等。</span>
                      <span v-else>Ensure network-level security, including firewalls, intrusion detection systems, and
                        more.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="introduceBlock_content" @click="toProduct()">
                <div class="introduce_block_four introduce_block">
                  <div class="introduce_block_img">
                    <img src="@/assets/page/icon_4.gif">
                  </div>
                  <div style="text-align: center;padding: 10px 20px;">
                    <div style="color: white;margin-top: 70px">
                      <span v-if="language==='1'">应用安全产品</span>
                      <span v-else>Application Security Products</span>
                    </div>
                    <div class="introduce" style="margin-top: 10px">
                      <span v-if="language==='1'">保护应用程序免受攻击，确保应用程序的安全性和数据的完整性。</span>
                      <span v-else>Protect applications from attacks, ensuring the security and integrity of
                        applications and data.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="introduceBlock_content" @click="toProduct()">
                <div class="introduce_block_four introduce_block">
                  <div class="introduce_block_img">
                    <img src="@/assets/page/icon_5.gif">
                  </div>
                  <div style="text-align: center;padding: 10px 20px;">
                    <div style="color: white;margin-top: 70px">
                      <span v-if="language==='1'">数据安全产品</span>
                      <span v-else>Application Security Products</span>
                    </div>
                    <div class="introduce" style="margin-top: 10px">
                      <span v-if="language==='1'">保护应用程序免受攻击，确保应用程序的安全性和数据的完整性。</span>
                      <span v-else>Protect applications from attacks, ensuring the security and integrity of
                        applications and data.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="introduceBlock_content" @click="toProduct()">
                <div class="introduce_block_four introduce_block">
                  <div class="introduce_block_img">
                    <img src="@/assets/page/icon_6.gif">
                  </div>
                  <div style="text-align: center;padding: 10px 20px;">
                    <div style="color: white;margin-top: 70px">
                      <span v-if="language==='1'">资产安全产品</span>
                      <span v-else>Application Security Products</span>
                    </div>
                    <div class="introduce" style="margin-top: 10px">
                      <span v-if="language==='1'">保护应用程序免受攻击，确保应用程序的安全性和数据的完整性。</span>
                      <span v-else>Protect applications from attacks, ensuring the security and integrity of
                        applications and data.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;background:#ffffff;height: 20px;position: absolute;z-index: 100"
               :style="{bottom:twoLineBottom}"></div>
        </div>

        <!--        <div v-if="index===3" style="width: 100%;z-index: 88;position: relative" class="fourPage">-->
        <!--          <div style="width: 100%;background:#ffffff;height: 20px;position: absolute;z-index: 100" :style="{top:threeLineTop}"></div>-->
        <!--          <div style="height: 83vh;overflow: hidden;">-->
        <!--            <div style="color: white;font-size: 17px;display: flex;justify-content: center;transition: all .4s ease-out;" :style="{marginTop:fourTop}">-->
        <!--              <div :class="fourPageIndex===1 ? 'activeNewsButton' : 'newsButton'" @click="fourPageIndex=1">安全事件</div>-->
        <!--              <div :class="fourPageIndex===2 ? 'activeNewsButton' : 'newsButton'" @click="fourPageIndex=2">安全事件</div>-->
        <!--              <div :class="fourPageIndex===3 ? 'activeNewsButton' : 'newsButton'" @click="fourPageIndex=3">安全事件</div>-->
        <!--            </div>-->
        <!--            <div style="width: 70%;margin: 30px auto 0 auto;" :style="{marginTop:four_one_top}">-->
        <!--              <div class="four_content" style="transition: all .8s ease-in-out" :style="{marginTop:four_one_top}">-->
        <!--                <img src="@/assets/test.png" class="fourImg">-->
        <!--                <div class="four_center_block">-->
        <!--                  <p class="newsTitle">5月Web3安全事件总损失约 1.24 亿美元</p>-->
        <!--                  <p class="newsInfo">2024年5月，共发生安全事件 31 起，总损失约 1.24 亿美元，原因涉及合约漏洞、地址污染攻击、跑路和账号被盗等。</p>-->
        <!--                </div>-->
        <!--                <div class="four_right_block">-->
        <!--                  <p class="timeDay">05-12</p>-->
        <!--                  <p class="timeYear">2024</p>-->
        <!--                  <div class="four_more"></div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="four_content" style="transition: all 1.1s ease-in-out" :style="{marginTop:four_two_top}">-->
        <!--                <img src="@/assets/test.png" class="fourImg">-->
        <!--                <div class="four_center_block">-->
        <!--                  <p class="newsTitle">4月Web3安全事件总损失约 9081 万美元</p>-->
        <!--                  <p class="newsInfo">2024年4月，共发生安全事件 37 起，总损失约 9081 万美元，原因涉及合约漏洞、第三方漏洞、跑路和账号被盗等。</p>-->
        <!--                </div>-->
        <!--                <div class="four_right_block">-->
        <!--                  <p class="timeDay">05-12</p>-->
        <!--                  <p class="timeYear">2024</p>-->
        <!--                  <div class="four_more"></div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="four_content" style="transition: all 1.5s ease-in-out" :style="{marginTop:four_three_top}">-->
        <!--                <img src="@/assets/test.png" class="fourImg">-->
        <!--                <div class="four_center_block">-->
        <!--                  <p class="newsTitle">3月Web3安全事件总损失约 1.39 亿美元</p>-->
        <!--                  <p class="newsInfo">2024年3月，共发生安全事件 33 起，总损失约 1.39 亿美元，原因涉及合约漏洞、内部作恶、闪电贷攻击、私钥泄露和账号被盗等。</p>-->
        <!--                </div>-->
        <!--                <div class="four_right_block">-->
        <!--                  <p class="timeDay">05-12</p>-->
        <!--                  <p class="timeYear">2024</p>-->
        <!--                  <div class="four_more"></div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div style="width: 100%;background:#ffffff;height: 20px;position: absolute;z-index: 100" :style="{bottom:threeLineBottom}"></div>-->
        <!--        </div>-->

        <div v-if="index===3" style="width: 100%;z-index: 88;position: relative" class="fivePage">
          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{top:fourLineTop}"></div>
          <div style="height: 80vh;overflow: hidden;">
            <div
                style="color: #bbbbbb;font-size: 17px;display: flex;justify-content: center;transition: all .4s ease-out;"
                :style="{marginTop:fiveTop}">
              <div :class="fivePageIndex===1 ? 'activeFiveNewsButton' : 'fiveNewsButton'" @click="fivePageIndex=1">
                <span v-if="language==='1'">合作伙伴</span>
                <span v-else>PATNERS</span>
              </div>
              <div :class="fivePageIndex===2 ? 'activeFiveNewsButton' : 'fiveNewsButton'" @click="fivePageIndex=2">
                <span v-if="language==='1'">客户案例</span>
                <span v-else>CASE STUDIES</span>
              </div>
            </div>
            <div style="width: 58%;margin: 30px auto 0 auto;" :style="{marginTop:five_one_top}">
              <div style="height: 30px;"></div>
              <div class="five_content" style="transition: all .5s ease-in-out" :style="{marginTop:five_one_top}">
                <div class="five_logoBox">
                  <img src="@/assets//partner/houbi.svg" style="width: 56%">
                </div>
                <div class="five_logoBox">
                  <img src="@/assets/partner/okx.png" style="width: 60%">
                </div>
                <div class="five_logoBox">
                  <img src="@/assets/partner/bybit.svg" style="width: 60%">
                </div>
                <div class="five_logoBox">
                  <img src="@/assets/partner/mexc.svg" style="width: 60%">
                </div>
                <div class="five_logoBox">
                  <img src="@/assets/partner/bitget.svg" style="width: 60%">
                </div>
              </div>
              <div class="five_content" style="transition: all 1.1s ease-in-out" :style="{marginTop:five_two_top}">
                <div class="five_logoBox">
                  <img src="@/assets/partner/gate.png" style="width: 60%">
                </div>
                <div class="five_logoBox">
                  <img src="@/assets/partner/nanKai.svg" style="width: 60%">
                </div>
                <div class="five_logoBox">
                  <img src="@/assets/partner/huaZhong.svg" style="width: 60%">
                </div>
                <div class="five_logoBox">
                  <img src="@/assets/partner/zhongShan.png" style="width: 60%">
                </div>
                <div class="five_logoBox" style="visibility: hidden">

                </div>
              </div>
              <!--              <div class="five_content" style="transition: all 1.5s ease-in-out" :style="{marginTop:five_three_top}">-->
              <!--                <div class="five_logoBox">-->
              <!--                  <img src="@/assets/logoBox.png">-->
              <!--                </div>-->
              <!--                <div class="five_logoBox">-->

              <!--                </div>-->
              <!--                <div class="five_logoBox">-->

              <!--                </div>-->
              <!--                <div class="five_logoBox">-->

              <!--                </div>-->
              <!--                <div class="five_logoBox">-->

              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="five_content" style="transition: all 2s ease-in-out" :style="{marginTop:five_four_top}">-->
              <!--                <div class="five_logoBox">-->
              <!--                  <img src="@/assets/logoBox.png">-->
              <!--                </div>-->
              <!--                <div class="five_logoBox">-->

              <!--                </div>-->
              <!--                <div class="five_logoBox">-->

              <!--                </div>-->
              <!--                <div class="five_logoBox">-->

              <!--                </div>-->
              <!--                <div class="five_logoBox">-->

              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{bottom:fourLineBottom}"></div>
        </div>

        <div v-if="index===4" style="width: 100%;z-index: 88;position: relative" class="sixPage">
          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{top:fiveLineTop}"></div>
          <div style="height: 80vh;overflow: hidden;">
            <div style="color: #bbbbbb;font-size: 17px;">
              <div style="height: 100px;margin-top: 15%">
                <div style="font-size: 40px;color: #131929;text-align: center;display: block;font-weight: 600;">COMPANY
                  PROFILE</div>
                <div class="sixTitle" style="transition: all 1.1s ease-out;" :style="{marginTop:sixTop}">
                  <span v-if="language==='1'">公司简介</span>
                  <span v-else>COMPANY PROFILE</span>
                </div>
              </div>
              <div style="width: 44%;margin: 0 auto;transition: all .8s ease" class="sixInfo"
                   :style="{marginTop:six_one_top}">
                <span v-if="language==='1'">
                  牧色科技是一家专注于区块链行业基础安全的公司，成立于2019年12月，团队成员来自火币、bitget、bybit等一线交易所，
                  拥有多年甲方安全建设和运营经验，成员背景多为国内顶级985院校，如南开大学信息安全硕士、中山大学计算机硕士等，牧色
                  科技专注于打造区块链安全行业基础安全的绝对防御，主要通过“安全建设到安全运营一体化的安全解决方案”提供长期的顶级安
                  全专家订阅服务。 牧色科技以业内最佳安全实践为基础，打造了一整套以企业资产为核心的纵深绝对防御体系，在各大顶级区块
                  链公司和互联网公司得到印证，致力于保障企业资产的绝对安全。
                </span>
                <span v-else>
                  Muse Technology (hereinafter referred to as Musesec) is a company focused on foundational security in
                  the blockchain industry.
                  Established in December 2019, the team members hail from top-tier exchanges such as Huobi,
                  Bitget, and Bybit, bringing years of experience in security construction and operations from
                  the client's perspective. Our team has strong academic backgrounds from top domestic 985 universities,
                  including Master's degrees in Information Security from Nankai University and Computer Science from
                  Sun
                  Yat-sen University. Musesec is dedicated to building an absolute defense in foundational blockchain
                  security.
                  We primarily offer top-tier security expert subscription services through "integrated security
                  solutions from
                  construction to operations."Based on the industry's best security practices, Musesec has developed a
                  comprehensive
                  in-depth defense system centered on enterprise assets. This system has been validated by leading
                  blockchain and
                  internet companies, committed to ensuring the absolute security of enterprise assets.
                </span>
              </div>
            </div>
          </div>
          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{bottom:fiveLineBottom}"></div>
        </div>
        <!-- 联系页面 -->
        <div v-if="index===5" style="width: 100%;z-index: 88;position: relative" class="severnPage">
          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{top:sixLineTop}"></div>
          <div style="overflow: hidden;width: 100%;height: 98vh;display: flex;">

            <!-- 左侧内容 -->
            <div class="seven_left_content fadeInDownBig" :style="{left:sevenLeft}">
              <div style="width: 70%;height: 100%;">
                <div style="font-weight: bold;font-size: large;width: 100%;">
                  <span v-if="language==='1'">深圳牧色科技有限公司</span>
                  <span v-else>Shenzhen Muse Technology Co., LTD</span>
                </div>
                <div class="line"></div>
                <!--                <div class="content">-->
                <!--                  <img src="@/assets/connect.png">-->
                <!--                  <div>17620397442</div>-->
                <!--                </div>-->
                <div class="content">
                  <img src="@/assets/email.png">
                  <div>team@musesec.com</div>
                </div>
                <div class="content">
                  <img src="@/assets/redBook.svg" v-if="language==='1'" style="width: 30px;margin-right: 30px;">
                  <img src="@/assets/telegram.svg" v-else style="width: 26px;margin-right: 30px;">
                  <div v-if="language==='1'">牧色科技</div>
                  <div v-else>@MuseSec/Channel：MuseSec</div>
                </div>
                <div class="content">
                  <img src="@/assets/twitter.png">
                  <div>@MuseSec_Team</div>
                </div>
                <div class="content">
                  <img src="@/assets/address.png">
                  <div>
                    <span v-if="language==='1'">深圳宝安区易尚创意科技大厦</span>
                    <span v-else>Yishang Creative Technology Building, Bao'an District, Shenzhen City.</span>
                  </div>
                </div>
                <div class="infoCode">
                  <div style="">
                    <img src="@/assets/weChat.png">
                    <div style="font-size: 12px;color: #1b1e22;">
                      <div v-if="language==='1'" style="margin-right: 6px;text-align: right;">牧色科技微信号</div>
                      <div v-else style="text-align: right;width: 60%;margin: 0 auto;">Wechat</div>
                    </div>
                  </div>
                  <div>
                    <img src="@/assets/publicAccount.png">
                    <div style="font-size: 12px;color: #1b1e22;">
                      <div v-if="language==='1'" style="text-align: right;;margin-right: 6px;">牧色科技公众号</div>
                      <div v-else style="text-align: center;width: 80%;margin-left: 20%">Wechat Official Account</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 右侧内容 -->
            <div class="seven_right_content" :style="{ right: sevenRight }">

              <div style="width: 70%;height: 100%;">
                <div style="font-weight: bold;font-size: large;width: 100%;">
                  <span v-if="language==='1'">联系我们</span>
                  <span v-else>Contact Us</span>
                </div>
                <div class="line"></div>
                <form action="/submit_user_info" method="post" style="display: flex; flex-direction: column;">
                  <label for="username" style="margin-bottom: 5px;">用户名:</label>
                  <input type="text" id="username" name="username" required
                         style="width: 100%; height: 40px; text-indent: 16px; color: #a2a7d1; border-radius: 10px; border: 2px solid #565d78; background: #171a29; font-size: 14px; margin-bottom: 19px;">

                  <label for="email" style="margin-bottom: 5px;">邮箱:</label>
                  <input type="email" id="email" name="email" required
                         style="width: 100%; height: 40px; text-indent: 16px; color: #a2a7d1; border-radius: 10px; border: 2px solid #565d78; background: #171a29; font-size: 14px; margin-bottom: 19px;">

                  <!-- <label for="password" style="margin-bottom: 19px;">密码:</label>
        <input type="password" id="password" name="password" required style="width: 100%; height: 40px; text-indent: 16px; color: #a2a7d1; border-radius: 10px; border: 1px solid #30364c; background: #171a29; font-size: 14px; margin-bottom: 19px;">

        <label for="confirm_password" style="margin-bottom: 19px;">确认密码:</label>
        <input type="password" id="confirm_password" name="confirm_password" required style="width: 100%; height: 40px; text-indent: 16px; color: #a2a7d1; border-radius: 10px; border: 1px solid #30364c; background: #171a29; font-size: 14px; margin-bottom: 19px;"> -->

                  <label for="phone" style="margin-bottom: 5px;">电话号码:</label>
                  <input type="tel" id="phone" name="phone"
                         style="width: 100%; height: 40px; text-indent: 16px; color: #a2a7d1; border-radius: 10px; border: 2px solid #565d78; background: #171a29; font-size: 14px; margin-bottom: 19px;">

                  <label for="address" style="margin-bottom: 5px;">项目描述或需求：</label>
                  <textarea id="address" name="address"
                            style="width: 100%; height: 100px; text-indent: 16px; color: #a2a7d1; border-radius: 10px; border: 2px solid #565d78; background: #171a29; font-size: 14px; margin-bottom: 19px;"></textarea>

                  <input type="submit" value="提交"
                         style="width: 100%; height: 40px; text-indent: 16px; color: #a2a7d1; border-radius: 10px; border: 2px solid #565d78; background: #171a29; font-size: 14px; margin-bottom: 19px;">
                </form>



              </div>

            </div>
          </div>
          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{bottom:sixLineBottom}"></div>
        </div>
        <div v-if="index===6" style="width: 100%;z-index: 88;position: relative" class="eightPage">
          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{top:severnLineTop}"></div>
          <div class="eight_content">
            <span v-if="language==='1'">智能合约审计证书验证</span>
            <span v-else>Smart Contract Audit Certificate Verification</span>
          </div>
          <div class="eight_infoTitle">
            <span v-if="language==='1'">牧色科技将通过智能合约审计后的审计证书存入链上，可以对相关证书进行验真。</span>
            <span v-else>Musesec will store audit certificates on the blockchain after conducting smart contract audits,
              allowing for the verification of these certificates.</span>
          </div>
          <div class="search_box">
            <input type="text" class="search_input">
            <img src="@/assets/search.svg">
          </div>
          <div style="position: absolute;bottom: 20px;;text-align: center;width: 100%;">
            <div style="color: white;display: flex;justify-content: center">
              <img src="@/assets/govIcon.gif" style="width: 22px">
              <p style="margin-top: 5px;font-size: 12px;margin-left: 8px;">粤ICP备2023149141号-1</p>
            </div>

          </div>

          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{bottom:severnLineBottom}"></div>
        </div>

        <div v-if="index===7"  style="width: 100%;z-index: 88;position: relative" class="ninePage">
          <div style="width: 100%;background:white;height: 20px;position: absolute;z-index: 100"
               :style="{top:nineLineTop}"></div>
          <div class="nine_content">
            <span v-if="language==='1'">黑U检测，限时免费</span>
            <span v-else>Smart Contract Audit Certificate Verification</span>
          </div>
          <div class="search_box" style="margin-top: 2%" @click="fetchData(blackSearch)">
            <input type="text" class="search_input" v-model="blackSearch">
            <img src="@/assets/search.svg">
          </div>
          <div style="background: white;width: 60%;min-height: 150px;margin: 0 auto;border-radius: 4px;text-align: left;padding: 20px" v-if="dangerInfo">
            <div style="font-weight: bold">
              地址：<span>{{appStateData.appContext.initialProps.store.pageState.address}}</span>
            </div>
            <div v-if="appStateData.appContext.initialProps.store.pageState.tagStore.tagMaps.isRisk" style="color: #eb4747;font-size: 14px">
              <img src="@/assets/danger.svg" style="width: 10px;margin-top: 14px">该地址被举报为 Scam 地址，请注意可能涉及的风险！
            </div>
            <div class="danger_info" style="margin-top: 20px;display: flex;border-top: 1px solid #ebebeb;" v-if="appStateData.appContext.initialProps.store.pageState.tagStore.tagMaps.isRisk" >
              <div class="danger_title">标签：</div>
              <div v-for="(item,index) in appStateData.appContext.initialProps.store.pageState.tagStore.tagMaps.riskTags" :key="index" class="danger_tags">#{{item}}</div>
            </div>
            <div class="danger_info" style="display: flex;margin-top: 20px;">
              <div class="danger_title">TRX余额：</div>
              <div>{{appStateData.appContext.initialProps.store.pageState.infoStore.state.balance}}TRX</div>
            </div>
            <div class="danger_info" style="display: flex">
              <div class="danger_title">币种总数：</div>
              <div>{{appStateData.appContext.initialProps.store.pageState.infoStore.state.tokenCountData.total}}</div>
              <el-popover
                  placement="top"
                  :width="200"
                  trigger="click">
                <div class="more_block">
                  <div>币种总数：</div>
                  <div>{{appStateData.appContext.initialProps.store.pageState.infoStore.state.tokenCountData.total}}</div>
                </div>
                <div class="more_block" v-for="(item , index) in appStateData.appContext.initialProps.store.pageState.infoStore.state.tokenCountData.tokenCategoryList" :key="index">
                  <div>{{item.tokenCategoryName}}代币</div>
                  <div>{{item.num}}</div>
                </div>
                <template #reference>
                  <div class="look_more" style="">查看详情</div>
                </template>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 黑底右侧圆点指示器 -->
    <div class="carousel-dots">
      <span v-for="(page, index) in carouselPages" :key="index" class="dot" :class="{ active: currentPage === index }"
            @click="goToPage(index)"></span>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, watch} from 'vue'

const carouselPages = ref([
  { title: '首页', description: 'Description for Page 1',bg:'#262626' ,Eg:'HOME'},
  { title: '服务', description: 'Description for Page 2',bg:'#464646',Eg:'SERVICE' },
  { title: '产品', description: 'Description for Page 3',bg:'#333333',Eg:'PRODUCT' },
  // { title: '新闻', description: 'Description for Page 4',bg:'#131313' ,Eg:'INSIGHTS'},
  { title: '伙伴', description: 'Description for Page 5',bg:'#3b3a3a' ,Eg:'PARTNER'},
  { title: '介绍', description: 'Description for Page 6',bg:'#484747',Eg:'ABOUT' },
  { title: '联系', description: 'Description for Page 7',bg:'#656565',Eg:'CONTACT' },
  { title: '报告认证',description:'Description for Page 7',bg:'#656565',Eg:'REPORTS & CERTIFICATIONS'},
  { title: '黑U检测',description:'Description for Page 7',bg:'#656565',Eg:'Black U detection'},
  // ...更多页面
])

const currentPage = ref(0)
let lastScrollTime = 0
const scrollThreshold = 1000 // 滚动事件之间的时间间隔阈值（毫秒）

import {useRoute, useRouter} from 'vue-router'
import axios from "axios";
import {ElMessage} from "element-plus";
const router = useRouter()
const route = useRoute()

const whiteLine=ref(false)
const pageTurned = ref(false);
const pageUp = ref(false);
const dangerInfo=ref(false)

const lineBottom=ref('-20px')
const lineTop=ref('-20px')
const oneLineBottom=ref('-20px')
const twoLineTop=ref('-20px')
const twoLineBottom=ref('-20px')
const threeLineTop=ref('-20px')
const threeLineBottom=ref('-20px')
const fourLineTop=ref('-20px')
const fourLineBottom=ref('-20px')
const fiveLineTop=ref('-20px')
const fiveLineBottom=ref('-20px')
const sixLineTop=ref('-20px')
const sixLineBottom=ref('-20px')
const severnLineTop=ref('-20px')
const severnLineBottom=ref('-20px')
const nineLineTop=ref('-20px')


const fourTop=ref('22%')
const fiveTop=ref('22%')
const sixTop=ref('-25%')
const six_one_top=ref('25%')
const four_one_top=ref('30%')
const five_one_top=ref('30%')
const four_two_top=ref('30%')
const five_two_top=ref('30%')
const four_three_top=ref('30%')
const five_three_top=ref('30%')
const five_four_top=ref('30%')
const sevenLeft=ref('-25%')
const sevenRight=ref('-25%')

const language=ref('1')

const fourPageIndex=ref(1)
const fivePageIndex=ref(1)

let timeout1=ref()
let timeout2=ref()
let blackSearch=ref();

let blackList=ref()

const toService = () => {
  router.push({path:'/service'})
};


const toProduct = () => {
  router.push({path:'/product'})
};


watch(currentPage, () => {
  console.log('pageUp',pageUp.value);
  if(currentPage.value===0){
    console.log('0');
    if(pageUp.value===true){
      lineTop.value = '0'
      lineBottom.value='-20px'
    }else {
      lineBottom.value='0'
      lineTop.value = '-20px'
    }
  }
  if(currentPage.value===1){
    console.log('1');
    if(pageUp.value===true){
      twoLineTop.value = '0'
      oneLineBottom.value='-20px'
    }else {
      lineBottom.value='0'
      lineTop.value='-20px'
    }
  }
  if(currentPage.value===2){
    console.log('2');
    if(pageUp.value===true){
      fourLineTop.value='0'
      twoLineBottom.value='-20px'
    }else {
      oneLineBottom.value='0'
      twoLineTop.value='-20px'
    }
  }
  if(currentPage.value===3){
    console.log('3');
    if(pageUp.value===true){
      fiveLineTop.value='0'
      fourLineBottom.value='-20px'
    }else {
      twoLineBottom.value='0'
      fourLineTop.value='-20px'
    }
    setTimeout(()=>{
      fiveTop.value='7%'
    },300)
    setTimeout(()=>{
      fiveTop.value='10%'
    },650)
    setTimeout(()=>{
      five_one_top.value='0'
    },600)
    setTimeout(()=>{
      five_two_top.value='0'
    },1000)
    setTimeout(()=>{
      five_three_top.value='0'
    },1500)
    setTimeout(()=>{
      five_four_top.value='0'
    },2000)
  }
  if(currentPage.value===4){
    console.log('4');
    if(pageUp.value===true){
      sixLineTop.value='0'
      fiveLineBottom.value='-20px'
    }else {
      fourLineBottom.value='0'
      fiveLineTop.value='-20px'
    }
    setTimeout(()=>{
      sixTop.value='-20px'
      document.querySelector('.sixTitle').style.opacity=1
    },600)
    setTimeout(()=>{
      six_one_top.value='1px'
    },600)
  }
  if(currentPage.value===5){
    console.log('5');
    if(pageUp.value===true){
      severnLineTop.value='0'
      sixLineBottom.value='-20px'
    }else {
      fiveLineBottom.value='0'
      sixLineTop.value='-20px'
    }
    timeout1.value=setTimeout(()=> {
      sevenLeft.value = '25%'
      sevenRight.value='25%'
      timeout2.value=setTimeout(() => {
        sevenLeft.value = '23.5%'
        sevenRight.value='23.5%'
      }, 1000)
    },800)
  }
  if(currentPage.value===6){
    if (pageUp.value===false){
      sixLineBottom.value='0'
      severnLineTop.value='-20px'
    }else {
      severnLineBottom.value='-20px'
      nineLineTop.value='0'
    }
  }
  if(currentPage.value===7){
    if (pageUp.value===false){
      severnLineBottom.value='0'
      nineLineTop.value='-20px'
    }
  }
  if(currentPage.value!==5){
    clearTimeout(timeout1.value)
    clearTimeout(timeout2.value)
  }
  sevenLeft.value='-35%'
  sevenRight.value='-35%'
  fourTop.value='25%'
  fiveTop.value='25%'
  sixTop.value='-25%'
  six_one_top.value='25%'
  four_one_top.value='30%'
  five_one_top.value='30%'
  four_two_top.value='30%'
  five_two_top.value='30%'
  four_three_top.value='30%'
  five_three_top.value='30%'
  five_four_top.value='30%'
});


onMounted(() => {
  document.addEventListener('wheel', handleWheel, { passive: true });
  if(sessionStorage.getItem('lang')){
    language.value=sessionStorage.getItem('lang')
  }
});

// 停止滚动
onUnmounted(() => {
  document.removeEventListener('wheel', handleWheel);
});

const handleWheel = (event) => {
  const now = Date.now();
  whiteLine.value=true
  if(event.deltaY<0){
    pageUp.value=true
  }else {
    pageUp.value=false
  }
//向上滑动
  if (now - lastScrollTime > scrollThreshold) {
    // 如果距离上次滚动事件超过1秒，则允许执行翻页操作
    currentPage.value += event.deltaY < 0 ? -1 : 1;
    currentPage.value = Math.max(0, Math.min(carouselPages.value.length - 1, currentPage.value));
    lastScrollTime = now;
  }
};
let startY = 0;
let isScrolling = false;
let hasScrolled = false; // 标记是否已经进行了滑动计算

const handleTouchStart = (event) => {
  startY = event.touches[0].pageY;
  isScrolling = true;
  hasScrolled = false; // 重置为未滑动状态
};

const handleTouchMove = (event) => {
  if (!isScrolling || hasScrolled) return; // 如果不是在滚动状态或已经滚动过，则不执行

  const deltaY = event.touches[0].pageY - startY;
  if (Math.abs(deltaY) > 5) { // 简单的阈值判断
    hasScrolled = true; // 标记为已滑动

    if (deltaY < 0) {
      // 向下滑动
      currentPage.value++;
    } else {
      // 向上滑动
      currentPage.value--;
    }

    // 限制当前页面索引在有效范围内
    currentPage.value = Math.max(0, Math.min(carouselPages.value.length - 1, currentPage.value));
    console.log('currentPage===', currentPage.value);
  }

  // 注意：这里不更新startY，因为我们只关心第一次touchmove
};

const handleTouchEnd = () => {
  isScrolling = false;
  // 如果需要，可以在这里添加一些结束时的逻辑
};

// 添加事件监听器
window.addEventListener('touchstart', handleTouchStart);
window.addEventListener('touchmove', handleTouchMove);
window.addEventListener('touchend', handleTouchEnd);

// 导航到指定页面
function goToPage(index) {
  // 直接滚动到目标位置
  const carousel = document.querySelector('.carousel-track')
  carousel.scrollTop = index * carousel.offsetHeight
  console.log('index',index);
  console.log('currentPage.value',currentPage.value);
  if(index>currentPage.value){
    pageUp.value=false
  }else {
    pageUp.value=true
  }
  currentPage.value = index
}

function changeLanguage(){
  if (language.value==='1'){
    language.value='2'
  }else {
    language.value='1'
  }
  sessionStorage.setItem('lang',language.value)
}


let appStateData=ref()


async function fetchData(address) {
  try {
    if(blackSearch.value){
      const url = `https://super.pei-wang.workers.dev/trx/address/${address}`;
      const response = await axios.get(url);
      console.log('response===',response);
      // const response = await fetch(url);
      const data = await response.data;
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'text/html');
      const scriptTag = doc.getElementById('appState');
      if (scriptTag) {
        const scriptContent = scriptTag.textContent.trim();
        console.log('scriptContent===',scriptContent);
        try {
          appStateData.value = JSON.parse(scriptContent);
          dangerInfo.value=true
        } catch (error) {
          console.error('解析JSON失败', error);
        }
      }
    }else {
      dangerInfo.value=false
      ElMessage.error('请输入检测地址')
    }
    // 处理数据...
  } catch (error) {
    console.error('加载数据失败', error);
  }
}

</script>

<style scoped lang="less">
@import "@/assets/css/home.css";
</style>
